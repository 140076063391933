import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ListWrapper } from 'Components';
import { ActiveSubscriptionType } from 'schemas';

const useStyles = makeStyles((theme) => ({
  subscriptionInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '24px',
  },
  list: {
    background: '#e4f9f8',
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: theme.spacing(2),
  },
  subscriptionPrice: {
    textTransform: 'uppercase',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  unSubscribeButton: {
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(1)}px auto`,
      display: 'block',
    },
  },
  listItem: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    width: '90%',
  },
  alert: {
    '& .MuiAlert-icon': {
      fontSize: 40,
    },
    border: 'none',
    fontSize: '20px',
  },
}));

export const ActiveSubscription = ({
  subscription,
  unsubscribe,
}: {
  subscription: ActiveSubscriptionType;
  unsubscribe: (subscription: ActiveSubscriptionType) => void;
}) => {
  const classes = useStyles();

  const isServiceEndDateExist = !!subscription.service_end_date;
  const isFirstSubscriptionPaymentInProcess =
    !!subscription && subscription.unsubscribe_url === null;

  return (
    <Box key={subscription.id}>
      <ListWrapper
        title="Моя підписка"
        className={classes.list}
        titleClassName={classes.listTitle}
      >
        <div>
          <Box>
            <div className={classes.subscriptionInfo}>
              <Typography
                variant="h5"
                component="h5"
                className={classes.subscriptionInfo}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: subscription.name,
                  }}
                ></div>
              </Typography>
              <Typography
                variant="h5"
                component="h5"
              >
                <span className={classes.subscriptionPrice}>
                  {subscription.price} грн/місяць
                </span>
              </Typography>

              <div className={classes.buttonWrapper}>
                <Button
                  className={classes.unSubscribeButton}
                  onClick={unsubscribe(subscription)}
                  color="primary"
                  variant="contained"
                  disabled={isFirstSubscriptionPaymentInProcess}
                >
                  {isFirstSubscriptionPaymentInProcess ? 'В обробці' : 'Відписатися'}
                </Button>
              </div>
            </div>
            {!!subscription.suspended && (
              <Alert
                variant="outlined"
                severity="warning"
                className={classes.alert}
              >
                <Typography
                  variant="h6"
                  component="p"
                >
                  {subscription.suspended.message}
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  className={classes.listItem}
                >
                  Ймовірні причини:
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  className={classes.listItem}
                >
                  <strong>Заблокована картка.</strong>
                  <br></br> Для оновлення картки Вам необхідно відписатися. До{' '}
                  {subscription.suspended.expired_date} у Вас буде можливість
                  повторно оформити підписку на попередніх умовах.
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  className={classes.listItem}
                >
                  <strong>Нестача коштів.</strong>
                  <br></br> У Вас є 72 години для поповнення рахунку. Списання
                  відбудеться автоматично - підписку буде відновлено. Після 72
                  годин, якщо кошти не надійдуть, підписку буде скасовано. У Вас
                  буде можливість повторно оформити підписку на попередніх
                  умовах до {subscription.suspended.expired_date}.
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  className={classes.listItem}
                >
                  <strong>Ліміт оплати в інтернеті.</strong>
                  <br></br> Зверніться в банк для зміни ліміту. Списання
                  відбудеться автоматично - підписку буде відновлено. Після 72
                  годин, якщо кошти не надійдуть, підписку буде скасовано. У Вас
                  буде можливість повторно оформити підписку на попередніх
                  умовах до {subscription.suspended.expired_date}.
                </Typography>
              </Alert>
            )}
          </Box>
        </div>
      </ListWrapper>
      {isServiceEndDateExist && (
        <Container>
          <Typography
            variant="h6"
            component="p"
          >
            Кінцева дата дії сервісу: {subscription.service_end_date}
          </Typography>
        </Container>
      )}
      {subscription.description && (
        <Container>
          <Typography
            variant="h5"
            component="h5"
            className={classes.subscriptionInfo}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: subscription.description,
              }}
            ></div>
          </Typography>
        </Container>
      )}
    </Box>
  );
};
