import moment from 'moment';
import logo from './logo.svg';
import { DISPLAY_DATES_FORMAT, FORM_LANG, MOUSE_SUBDOMAIN, SPACE_SUBDOMAIN } from 'Constants';

export const ResponsibilityForm = React.forwardRef(({ client, subdomain }, ref) => {
  const [state, changeState] = React.useState({
    lang: FORM_LANG.uk,
    isOpened: false,
  });

  const renderResponsibilityFormUkr = {
    [MOUSE_SUBDOMAIN]: <ResponsibilityFormUkrClimbingMouse client={client} />,
    [SPACE_SUBDOMAIN]: <ResponsibilityFormUkr client={client} logo={logo} />
  }

  function print(lang) {
    changeState({
      lang,
      isOpened: true,
    });
  }

  React.useEffect(() => {
    if (state.isOpened) {
      window.print();
      changeState({
        ...state,
        isOpened: false,
      });
    }
  }, [state.isOpened]);

  React.useImperativeHandle(ref, () => ({
    print,
  }));

  return (
    <div className="responsibility-form">
      {state.lang === FORM_LANG.en && (
        <ResponsibilityFormEng
          client={client}
          logo={logo}
        />
      )}

      {state.lang === FORM_LANG.uk && renderResponsibilityFormUkr[subdomain]}
    </div>
  );
});

function ResponsibilityFormUkr({ client, logo }) {
  return (
    <>
      <div className="responsibility-form__header">
        <h3 className="responsibility-form__title">
          Форма відповідальності № 1
        </h3>
        <img
          className="responsibility-form__logo"
          src={logo}
        />
      </div>
      <div className="responsibility-form__clear-both"></div>
      <p className="responsibility-form__client-data">
        Я, {client.first_name}&nbsp;{client.last_name}
      </p>
      {!!client.birthday && (
        <p className="responsibility-form__client-data">
          Дата народження:{' '}
          {moment(client.birthday).format(DISPLAY_DATES_FORMAT)}
        </p>
      )}
      {!client.birthday && (
        <p className="responsibility-form__client-data form-empty-line">
          <span className="form-empty-line__key">Дата народження:</span>
          <span className="form-empty-line__line"></span>
        </p>
      )}
      <p className="responsibility-form__client-data">
        Тел: {client.phone}. Email: {client.email}
      </p>
      <p className="responsibility-form__info-title">Підтверджую, що:</p>
      <ol className="responsibility-form__info-list">
        <li className="responsibility-form__info-list-item">
          Обізнаний, що скелелазіння травмонебезпечний вид спорту і заняття ним
          може спричинити травми різних ступенів тяжкості.
        </li>
        <li className="responsibility-form__info-list-item">
          Ознайомлений (а) з правилами відвідування і технікою безпеки, які
          діють на скеледромі, і забов'язуюсь неухильно їх дотримуватися.
        </li>
        <li className="responsibility-form__info-list-item">
          Скеледром не несе відповідальності в разі отримання мною травм або
          інших ушкоджень під час тренувань або перебування на території
          скеледрому.
        </li>
      </ol>
      <p className="responsibility-form__client-data">
        Своїм підписом засвідчую згоду на обробку моїх персональних даних –
        прізвища, імені, імені по батькові, дати народження, контактних даних,
        зображення – будь-яким законним способом.
      </p>
      <div className="responsibility-form__signature-wrapper">
        <p>
          _________________________<span>Дата</span>{' '}
        </p>
        <p>
          __________________<span>Підпис</span>
        </p>
        <p>
          _________________________<span>Прiзвище та iнiцiали</span>
        </p>
      </div>
    </>
  );
}

function ResponsibilityFormUkrClimbingMouse({ client }) {
  return (
    <div className="climbing-mouse-responsibility-form">
      <h3 className="climbing-mouse-responsibility-form__title">
        Заява про приєднання до публічного договору <br/> про надання послуг на скеледромі «Climbing Mouse» від {moment(client.created_at).format(DISPLAY_DATES_FORMAT)}
      </h3>
      <h3 className="climbing-mouse-responsibility-form__title--uppercase">
        Форма відповідальності
      </h3>
      <p className="climbing-mouse-responsibility-form__client-data">
        Я, (Замовник) {client.last_name} {client.first_name}
      </p>
      <p className="climbing-mouse-responsibility-form__client-data form-empty-line">
        <span className="form-empty-line__key">РНОКПП</span>
        <span className="form-empty-line__line"></span>
      </p>
      <p className="climbing-mouse-responsibility-form__client-data form-empty-line">
        <span className="form-empty-line__key">Документ, що посвідчує особу</span>
        <span className="form-empty-line__line"></span>
      </p>
      {!!client.birthday && (
        <p className="climbing-mouse-responsibility-form__client-data">
          Дата народження (день/місяць/рік): {moment(client.birthday).format(DISPLAY_DATES_FORMAT)}
        </p>
      )}
      {!client.birthday && (
        <p className="climbing-mouse-responsibility-form__client-data">
          Дата народження (день/місяць/рік):
        </p>
      )}
      <p className="climbing-mouse-responsibility-form__client-data">
        Контактний номер телефону: {client.phone}
      </p>
      <p className="climbing-mouse-responsibility-form__client-data">
        Електронна пошта: {client.email}
      </p>
      <p className="climbing-mouse-responsibility-form__info-title">
        Підписанням цієї Форми підтверджую, що:
      </p>
      <ol className="climbing-mouse-responsibility-form__info-list">
        <li className="climbing-mouse-responsibility-form__info-list-item">
          Обізнаний (а), що скелелазіння – травмонебезпечний вид спорту і
          заняття яким може спричинити негативні наслідки, в тому числі, але не
          виключно травми різних ступенів тяжкості та навіть смерть.
        </li>
        <li className="climbing-mouse-responsibility-form__info-list-item">
          Ознайомлений з умовами Публічного договору про надання послуг ФОП
          Устименко Ксенія Олесандрівна, Правилами відвідування та безпеки
          скеледрому ФОП Устимекно Ксенія, а також з усією іншою інформацією,
          яка необхідна для повноцінного споживання послуг та зобов’язуюсь
          неухильно їх дотримуватись. При цьому, підтверджую, що у разі внесення
          змін/редагування таких документів, такі вважаються доведеними до мого
          відома, а їх дія поширюються на мене, як на Замовника, незважаючи на
          те, що такі були прийняті після підписання цієї Форми.
        </li>
        <li className="climbing-mouse-responsibility-form__info-list-item">
          У мене відсутні будь-які медичні протипоказання для занять
          скелелазінням та іншою фізичною активністю.
        </li>
        <li className="climbing-mouse-responsibility-form__info-list-item">
          Фізична особа-підприємець Устименко Ксенія Олександрівна
          (ідентифікаційний код: 3719000946) та/ або Працівники Клубу (персонал
          Клубу, адміністрація, рецепція Клубу тощо) не несуть відповідальності
          в разі отримання мною травм або інших ушкоджень під час тренувань або
          перебування на території скеледрому (спортивного клубу).
        </li>
        <li className="climbing-mouse-responsibility-form__info-list-item">
          Фізична особа-підприємець Устименко Ксенія Олександрівна
          (ідентифікаційний код: 3719000946) та/ або Працівники Клубу (персонал
          Клубу, адміністрація, рецепція Клубу тощо) не несуть відповідальності
          за забуті, загублені, викрадені або залишені без нагляду мною речі.
        </li>
        <li className="climbing-mouse-responsibility-form__info-list-item">
          За наявності негативних (нещасних) випадків, у тому числі, але не
          виключно, пов’язаних із моїм здоров’ям, я самостійно несу
          відповідальність за їх настання.
        </li>
        <li className="climbing-mouse-responsibility-form__info-list-item">
          Надані мною персональні дані, які зазначаються в цій Формі, необхідні
          для моєї ідентифікації, є моїм добровільним волевиявленням щодо
          дозволу на обробку моїх персональних даних та їх використання в рамках
          чинного законодавства України.
        </li>
      </ol>
      <div className="climbing-mouse-responsibility-form__signature-wrapper">
        <p className="form-empty-line">
          <span className="form-empty-line__key">Дата</span>
          <span className="form-empty-line__fixed-width-100px"></span>
        </p>
        <p className="form-empty-line">
          <span className="form-empty-line__key">Підпис Замовника</span>
          <span className="form-empty-line__fixed-width-100px"></span>
        </p>
      </div>
    </div>
  );
}

function ResponsibilityFormEng({ client, logo }) {
  return (
    <>
      <div className="responsibility-form__header">
        <h3 className="responsibility-form__title">
          Form of responsibility №1
        </h3>
        <img
          className="responsibility-form__logo"
          src={logo}
        />
      </div>
      <div className="responsibility-form__clear-both"></div>
      <p className="responsibility-form__client-data">
        Name, Surname: {client.first_name}&nbsp;{client.last_name}
      </p>
      {!!client.birthday && (
        <p className="responsibility-form__client-data">
          Day of birthday:{' '}
          {moment(client.birthday).format(DISPLAY_DATES_FORMAT)}
        </p>
      )}
      {!client.birthday && (
        <p className="responsibility-form__client-data form-empty-line">
          <span className="form-empty-line__key">Day of birthday:</span>
          <span className="form-empty-line__line"></span>
        </p>
      )}

      <p className="responsibility-form__client-data">
        Phone number: {client.phone}. Email: {client.email}
      </p>
      <p className="responsibility-form__info-title">I certify that:</p>
      <ol className="responsibility-form__info-list">
        <li className="responsibility-form__info-list-item">
          I admit that climbing is a traumatic dangerous sport and can cause
          injuries of varying severity.
        </li>
        <li className="responsibility-form__info-list-item">
          I am familiar with the rules of attendance and safety rules applicable
          to the climbing walls, and I undertake to strictly adhere to them.
        </li>
        <li className="responsibility-form__info-list-item">
          The climbing hall is not responsible in case of injuries or other
          injuries during training or stay on the climbing area.
        </li>
      </ol>
      <p className="responsibility-form__client-data">
        By my signature I certify consent to the processing of my personal data
        - surname, first name, patronymic, date of birth, contact details,
        images - by any legal means.
      </p>
      <div className="responsibility-form__signature-wrapper">
        <p>
          _________________________<span>Date</span>{' '}
        </p>
        <p>
          __________________<span>Signature</span>
        </p>
        <p>
          _________________________<span>Full name</span>
        </p>
      </div>
    </>
  );
}